import React, { useCallback, useEffect, useState } from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { sendEvent } from '@utils/Analytics';
import { DefaultDialog } from '@ui/Dialog';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { PluginType } from '@components/Plugins/common/PluginTypes';
import { getAdditionalPropertyBagFieldByEntryPointId } from '@components/FlowBuilder/utils/Analytics';
import { usePageConnected } from '@utils/FacebookPages/usePageConnected';
import { usePlanLimitReached } from '@utils/Data/Pricing';
import { useWhatsappEnabled } from '@utils/Whatsapp';
import { useAdCommentsAutoreplyEnabled } from '@utils/useAdCommentsAutoreplyEnabled';
import {
  CategoryMenu,
  ItemsList,
  CategoryContext,
  getCategoriesData,
  Category,
} from '@components/ItemsModal';
import {
  GetPlanLimitReachedTextPath,
  PlanLimitReachedCallout,
} from '@components/PlanLimitReachedCallout';
import { TierType, Platform } from '@globals';
import { EntryPointItem, getEntryPointsItems } from './entryPoints';
import { EntryPointModalItem } from './components/EntryPointModalItem';
import * as css from './EntryPointsModal.css';
import { BlockShortcutId } from '@components/FlowBuilder/views/helpers/blocksShortcuts';

interface EntryPointsModalProps {
  flowId: string;
  platform: Platform;
  onSelect: (selectedId: PluginType | BlockShortcutId) => void;
  onClose: () => void;
}

const getPlanLimitReachedTextPath: GetPlanLimitReachedTextPath = (
  dialogPricingEnabled: boolean,
  currentTier: string | null | undefined,
) => {
  if (!dialogPricingEnabled) {
    return currentTier !== TierType.trial
      ? {
          title:
            'modernComponents.EntryPointsModal.planLimitReached.subscribersPayment.title',
          button:
            'modernComponents.EntryPointsModal.planLimitReached.subscribersPayment.button',
        }
      : {
          title:
            'modernComponents.EntryPointsModal.planLimitReached.subscribers.title',
          button:
            'modernComponents.EntryPointsModal.planLimitReached.subscribers.button',
        };
  }
  return {
    title:
      currentTier !== TierType.trial
        ? 'modernComponents.EntryPointsModal.planLimitReached.dialogues.title.included'
        : 'modernComponents.EntryPointsModal.planLimitReached.dialogues.title.free',
    button:
      'modernComponents.EntryPointsModal.planLimitReached.dialogues.button',
  };
};

export const EntryPointsModal: React.FC<EntryPointsModalProps> = ({
  onClose,
  onSelect,
  platform,
  flowId,
}) => {
  const { t } = useSafeTranslation();
  const { isConnected } = usePageConnected();
  const { isWhatsappEnabled } = useWhatsappEnabled();
  const { isAdCommentsAutoreplyEnabled } = useAdCommentsAutoreplyEnabled();
  const entryPointsItems = getEntryPointsItems({
    isWhatsappEnabled,
    isAdCommentsAutoreplyEnabled,
  });
  const [selectedId, setSelectedId] = useState<
    PluginType | BlockShortcutId | null
  >(null);
  const categories = getCategoriesData(entryPointsItems);
  const [activeCategory, setActiveCategory] = useState<Category>(
    !platform
      ? Category.facebook
      : categories.find((category) => category.platform === platform)
          ?.category || Category.facebook,
  );
  const planLimitReached = usePlanLimitReached();

  useEffect(() => {
    sendEvent({
      category: 'entry points modal',
      action: 'show',
    });
  }, []);

  const onDismiss = useCallback(() => {
    sendEvent({
      category: 'entry points modal',
      action: 'close',
      propertyBag: {
        flowId,
        platform,
        'page connected': isConnected.toString(),
      },
    });
    onClose();
  }, [flowId, isConnected, onClose, platform]);

  const selectHandler = useCallback(
    (type: PluginType | BlockShortcutId) => {
      if (selectedId) {
        return;
      }
      setSelectedId(type);
      onSelect(type);
      sendEvent({
        category: 'entry points screen',
        action: 'choose entry point',
        label: type,
        propertyBag: {
          'page connected': isConnected.toString(),
          flowId,
          platform,
          ...getAdditionalPropertyBagFieldByEntryPointId(type),
        },
      });
    },
    [selectedId, flowId, isConnected, onSelect, platform],
  );

  const entryPointsItemsToShow = entryPointsItems.filter(
    (item) => !activeCategory || item.category === activeCategory,
  );

  return (
    <DefaultDialog
      header={t('modernComponents.EntryPointsModal.commonSession.header')}
      onDismiss={onDismiss}
      dialogStyle={{
        backgroundColor: 'var(--white)',
        margin: '16px',
        width: 'calc(100vw - 32px)',
        height: '650px',
        maxWidth: '1048px',
      }}
      contentClassName={css.content}
      closeButtonStyle={{
        backgroundColor: 'var(--white)',
      }}
    >
      <CategoryContext.Provider
        value={{
          activeCategory,
          setActiveCategory,
        }}
      >
        <Flex flexDirection="column">
          {categories.length > 1 && (
            <>
              <CategoryMenu
                categories={categories}
                platform={platform}
                onSelect={(category) => {
                  sendEvent({
                    category: 'entry points modal',
                    action: category
                      ? `click ${category.toLowerCase()} category`
                      : 'click all items',
                    propertyBag: {
                      platform,
                      flowId,
                      'page connected': isConnected.toString(),
                    },
                  });
                }}
              />
              <Spacer factor={4} horizontalFactor={4} />
            </>
          )}
          <ItemsList<EntryPointItem>
            callout={
              planLimitReached?.visible && (
                <PlanLimitReachedCallout
                  {...planLimitReached}
                  sourcePage="entry point modal"
                  onClick={onClose}
                  getTextPath={getPlanLimitReachedTextPath}
                />
              )
            }
            short={planLimitReached?.visible}
            itemsToShow={entryPointsItemsToShow}
            renderItem={(item) => (
              <EntryPointModalItem
                onClick={() => selectHandler(item.id)}
                item={item}
                showLoader={selectedId === item.id}
              />
            )}
          />
        </Flex>
      </CategoryContext.Provider>
    </DefaultDialog>
  );
};
